import { useEffect } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import { useMutation } from '@tanstack/react-query';
import { atom, useAtom } from 'jotai';

import {
  createAtomicStateStateAtom,
  useAtomicStateAtomSetState,
} from 'src/atoms/atomic-state';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { getListingsUserPreferences } from 'src/entities/listing/api';

import { useLoginWall } from './useLoginWall';

import { PepitaSnackbar, usePepitaSnackbar } from 'src/libs/ui/pepita-snackbar';

import { getRealEstateSectionFromEntryPoint } from 'src/tracking/utils';

import type { RealEstateKey, SaveAdTrackingData } from 'src/types/real-estate';

import { http } from 'src/utils/client/http';

import { useRealEstateTracking } from 'src/views/RealEstate/hooks/useRealEstateTracking';

function getListingType(data: RealEstateKey) {
  return data.type === 'project' ? 'progetti' : 'annunci';
}

function updateRealEstateUserSave(
  listing: RealEstateKey,
  value: boolean,
  fromLogin = false
) {
  const url = `/api/utente/${getListingType(listing)}/salvati/${listing.id}`;

  if (value) {
    // Using only .post() as we ignore the response
    http.post(url);
  } else {
    // Using only .delete() as we ignore the response
    http.delete(url);
  }

  return { fromLogin };
}

type Params = {
  key?: RealEstateKey;
  initialValue?: boolean;
  idGeoHash?: string;
  showSnackbar?: boolean;
  forceSaved?: boolean;
  autofetch?: boolean;
  hasOldSave?: boolean;
  trackingData?: SaveAdTrackingData;
};

export const savedListingsAtom = atom(new Set<number>());

/**
 * Currently saved markers (before we get updates from server)
 */
export const savedMarkersAtom = createAtomicStateStateAtom({
  key: 'SavedMarkersAtom',
  deepEqualityCheck: true,
  default: {},
});

export const useRealEstateSave = ({
  key,
  initialValue,
  idGeoHash,
  showSnackbar = true,
  forceSaved = false,
  autofetch = true,
  hasOldSave = true,
  trackingData,
}: Params) => {
  const { user } = useAuthContext();
  const [savedListings, setSavedListings] = useAtom(savedListingsAtom);
  const setMarkerState = useAtomicStateAtomSetState(savedMarkersAtom);
  const saved = key ? savedListings.has(key.id) : false;

  function setSaved(value: boolean) {
    if (!key) return;

    setSavedListings((prevSavedListings) => {
      const newSavedListings = new Set(prevSavedListings);

      if (value) {
        return newSavedListings.add(key.id);
      }

      newSavedListings.delete(key.id);

      return newSavedListings;
    });
    if (idGeoHash) {
      setMarkerState((state) => ({
        ...state,
        [idGeoHash]: value,
      }));
    }
  }

  /**
   * We set the saved value following this order:
   * - the last user interaction with this real estate the save action
   * - the saved value sent from the data api
   * - we fetch the value from an api
   */
  useEffect(() => {
    if (!key || !user) return;

    // the saved value sent from the data api
    if (initialValue) {
      setSaved(initialValue);

      return;
    }

    if (autofetch) {
      getListingsUserPreferences([key]).then(([preferences]) => {
        setSaved(Boolean(preferences.saved));
      });
    }
  }, [key?.id, key?.type, initialValue]);

  const { trans } = useTranslations();

  const { trackRealEstateSave } = useRealEstateTracking({
    section: getRealEstateSectionFromEntryPoint(),
    realEstateId: key?.id ?? 0,
  });

  const setSnackbar = usePepitaSnackbar();

  const login = useLoginWall();

  const api = (value: boolean) => {
    if (!key) return Promise.resolve({ fromLogin: false });

    if (isFeatureEnabled('REALESTATE_MODAL_NOT_LOGGED') && !hasOldSave) {
      return Promise.resolve(updateRealEstateUserSave(key, value));
    }

    return login().then(({ fromLogin }) => {
      return updateRealEstateUserSave(key, value, fromLogin);
    });
  };

  const mutation = useMutation<
    { fromLogin: boolean },
    null,
    boolean,
    { prev: boolean }
  >({
    mutationFn: api,
    onMutate(value) {
      // On saved map we need to change state at the end of the api,
      // in order to update the markers count
      !forceSaved && user && setSaved(value);

      return { prev: !value };
    },
    onError(_, __, context) {
      const { prev } = context || { prev: false };

      setSaved(prev);
    },
    //saved in this moment is opposite of real value
    onSuccess({ fromLogin } = { fromLogin: false }, variables) {
      /**
       * checking if the successful callback matches the login API and
       * in this case we don't need to check if the user
       * is authenticated because we did it on onMutate Step
       *
       */

      (forceSaved || fromLogin) && setSaved(variables);

      /** TODO: una volta che le due modali di login
       * saranno unificate si potrà far passare tutto il flusso du traching
       * da questo unico punto, per ora in questo if arriva solo il flusso del dettaglio
       *
       */

      if (trackingData && fromLogin && key) {
        const { type } = key;

        trackRealEstateSave({
          saved: true,
          'Promoted Type Listing': type,
        });
      }

      showSnackbar &&
        setSnackbar(
          <PepitaSnackbar variant="success">
            {variables
              ? trans('lbl_bookmarked_ad', {
                  capitalize: true,
                })
              : trans('lbl_unsaved_ad', {
                  capitalize: true,
                })}
          </PepitaSnackbar>
        );
    },
  });

  return {
    state: saved,
    setState: mutation.mutate,
  };
};
